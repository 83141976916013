import React from "react";
import { Link } from "react-router-dom";
import macchina from "../../assets/imgs/macchina.svg";
import BottomNavigation from "../../components/BottomNavigation";
import TitleWithBack from "../../components/TitleWithBack";
import moment from "moment";
import Button from "../../components/Button";
import Toast from "../../components/Toast";
import { useConfermaPrenotazionePage } from "./useConfermaPrenotazionePage";
import "./style.css";

export const ConfermaPrenotazione = () => {
  const {
    bookNow,
    back,
    slotFrom,
    slotTo,
    date,
    booked,
    posto,
    busy,
    disabled,
    errorMessage,
  } = useConfermaPrenotazionePage();

  return (
    <>
      <div id="confirm-booking-page" className="booking-detail-page">
        <div className="box--3">
          <div className="divider--4"></div>

          {booked ? (
            <h2>prenotazione confermata</h2>
          ) : (
            <TitleWithBack title={"richiesta prenotazione"} onClick={back} />
          )}

          <div className="divider--4"></div>

          <div className="booking-card rounded bg--white shadow text--center text--distracting">
            <div className="divider--3"></div>
            <p className="text--bold">{moment(date).format("DD.MM.YY")}</p>
            <div className="divider--1"></div>
            <div
              className={`bg--xxLightGray rounded display--ib padding--1 ${
                booked ? "" : "hidden"
              }`}
            >
              <p className="text--semibold">Posto</p>
              <p className="text--bold fg--primary">{posto}</p>
            </div>
            <div className="divider--3"></div>
            <img className="icon-macchina vibrate-1" src={macchina} alt="" />
            <div className="terreno-macchina vibrate-2 fade-in"></div>
            <div className="divider--1"></div>
            <p className="text--bold">
              {" "}
              <span className="fg--secondary">{slotFrom}</span> -{" "}
              <span className="fg--primary">{slotTo}</span>{" "}
            </p>
            <div className="divider--3"></div>
          </div>

          <div className="divider--4"></div>

          {booked ? (
            <Link to="/soste-programmate">
              <button className="button inverse">vedi soste programmate</button>
            </Link>
          ) : (
            <Button
              disabled={disabled}
              onClick={bookNow}
              text="conferma prenotazione"
              isLoading={busy}
            />
          )}
        </div>

        {disabled && 
        <p className="wait-booking-time">Attendi. Il pulsante si abiliterà automaticamente all'apertura delle prenotazioni</p>
        }
      </div>

      <BottomNavigation />

      <Toast open={errorMessage.length}>{errorMessage}</Toast>
    </>
  );
};
