import { useSession } from "./useSession";
import { useState, useEffect } from "react";
import { ApiService as api } from "../services/ApiService";
import moment from "moment";

export const useBookings = (programmate = true) => {
    const { read, update } = useSession();
    const [userBookings, setUserBookings] = useState([]);
    const [ready, setReady] = useState(false);

    const getUserBookings = async () => {
        try {
            if (programmate) {
                document.querySelector(".elenco_soste-prog").classList.add("loading");
            } else {
                document.querySelector(".elenco_soste-effet").classList.add("loading");
            }
        } catch (error) {}

        setReady(false);
        const bookingsRes = await api.ListaPrenotazioni({
            IdUtente: read("userId"),
            Data: programmate ? "1977-01-01 13:36:00" : moment().subtract(30, "days").format("YYYY-MM-DD HH:mm:ss"),
            DataFine: programmate ? "2099-01-01 13:36:00" : moment().format("YYYY-MM-DD HH:mm:ss"),
        });

        const allOrders = bookingsRes.listaPrenotazioni || [];

        const futureOrders = allOrders.filter((_) => {
            if (programmate) {
                return moment(_.dataInizio) > moment().subtract(1, "days");
            } else {
                return moment(_.dataInizio) <= moment().subtract(1, "days");
            }
        });
        futureOrders.sort((a, b) => {
            return programmate ? moment(a.dataInizio) - moment(b.dataInizio) : moment(b.dataInizio) - moment(a.dataInizio);
        });

        const groupedOrders = futureOrders.reduce((acc, booking) => {
            // const key = `${booking.dataInizio}-${booking.dataFine}`;
            // const key = booking.numeroPrenotazione;
            // const key = `${booking.dataInizio}-${booking.numeroPrenotazione.slice(0, -6)}`;
            const key = `${booking.dataInizio}-${booking.numeroPrenotazione}-${booking.postoAuto}`;

            if (!acc[key]) {
                acc[key] = {
                    ...booking,
                    stato: booking.stato, // Initialize with the first booking's status
                };
            } else {
                // Update the group's status if necessary
                if (booking.stato !== "Annullato") {
                    acc[key].stato = "In attesa";
                }
            }

            return acc;
        }, {});
        setUserBookings(Object.values(groupedOrders));

        update("userBookings", futureOrders);
        setReady(true);

        setTimeout(() => {
            try {
                if (programmate) {
                    document.querySelector(".elenco_soste-prog").classList.remove("loading");
                } else {
                    document.querySelector(".elenco_soste-effet").classList.remove("loading");
                }
            } catch (error) {}
        }, 50);
    };

    useEffect(() => {
        getUserBookings();
    }, []);

    return {
        userBookings,
        ready,
    };
};
