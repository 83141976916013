import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/it";
import { ApiService as api } from "../../services/ApiService";
import { useSession } from "../../hooks/useSession";

// import { loadDomainCss } from "../../services/env";
import { useDomainCss } from "../../hooks/useDomainCss";

export const usePrenotaPage = () => {
    moment.locale("it");
    const { read, update } = useSession();
    const {} = useDomainCss();

    const [slots, setSlots] = useState([
        {
            id: "1",
            from: 0,
            to: 23,
            text: "00:00 - 23:59",
        },
    ]);

    useEffect(() => {
        const fetchSlot = async () => {
            const slots = await api.GetHourSlots();
            const mappedSlots = slots.map((s) => {
                return {
                    id: s.id,
                    from: parseInt(s.start),
                    to: parseInt(s.end),
                    text: `${s.start.slice(0, s.start.lastIndexOf(":"))} - ${s.end.slice(0, s.end.lastIndexOf(":"))}`,
                };
            });

            update("selectedSlot", mappedSlots[0]); // default select first slot from service
            setSelectedSlot(mappedSlots[0]); // default select first slot from service
            setSlots(mappedSlots);
        };

        const fetchMaps = async () => {
            // avoid any duplicate call if we've already called that service
            if(!read("maps") || !Object.keys(read("maps")).length) {
                const maps = await api.getMaps();
                update("maps", maps);
            }
        }

        fetchSlot();
        fetchMaps();
    }, []);

    const [selectedSlot, setSelectedSlot] = useState(read("selectedSlot") || slots[0]);
    const [selectedDate, setSelectedDate] = useState(read("selectedDate"));
    const [currentMonthIdx, setCurrentMonthIdx] = useState(0);
    const [currentMonth, setCurrentMonth] = useState(null);
    const [daysInCurrentMonth, setDaysInCurrentMonth] = useState([]);
    const navigate = useNavigate();
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);
    const [lastDate, setLastDate] = useState();

    const handleCalendarTouchStart = (e) => {
        setTouchStart(e.targetTouches[0].clientX);
    };

    const handleCalendarTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const handleCalendarTouchEnd = () => {
        if (touchStart - touchEnd > 150) {
            newload(1);
        }

        if (touchStart - touchEnd < -150) {
            newload(-1);
        }
    };

    useEffect(() => {
        newload();
    }, []);

    const newload = async (qty = 0) => {
        try {
            document.querySelector("#booking-calendar").classList.add("loading");
        } catch (error) {}
        setDaysInCurrentMonth([]);

        const newMonthIdx = currentMonthIdx + qty;
        setCurrentMonthIdx(newMonthIdx);

        const currMonth = moment().add(newMonthIdx, "month");
        setCurrentMonth(currMonth);

        const startDate = moment(currMonth.clone().startOf("month")).startOf("day");
        const endDate = moment(currMonth.clone().endOf("month")).startOf("day");

        const currentTimestamp = moment().toDate().getTime();
        const bookingsRes = await api.ListaPrenotazioni({
            IdUtente: read("userId"),
            Data: startDate.format("YYYY-MM-DD hh:mm:ss"),
            DataFine: endDate.format("YYYY-MM-DD hh:mm:ss"),
        });
        update("opensIn", currentTimestamp + bookingsRes.opensIn);

        const allBookings = bookingsRes.listaPrenotazioni || [];
        const availDateFrom = moment(bookingsRes.reserveFrom || {});
        const availDateTo = moment(bookingsRes.reserveTo || {});
        setLastDate(availDateTo);

        let _daysInCurrentMonth = [];
        do {
            const currDateClone = startDate.clone();
            const orders = allBookings.filter((_) => {
                const dateObj = moment(_.dataInizio).clone();
                return currDateClone.isSame(dateObj, "date") && _.stato != "Annullato";
            });
            let disabled = false;

            // if (currDateClone.isBefore(moment(), "date")) disabled = true;
            if (!currDateClone.isBetween(availDateFrom, availDateTo, "days", "[]")) {
                disabled = true;
            }

            _daysInCurrentMonth.push({
                dateObj: currDateClone,
                orders,
                disabled,
            });
        } while (startDate.add(1, "days").diff(endDate) <= 0);
        const maxIdx = _daysInCurrentMonth[0].dateObj.weekday() - 1;
        for (let index = 0; index <= maxIdx; index++) {
            _daysInCurrentMonth.unshift(null);
        }

        setDaysInCurrentMonth(_daysInCurrentMonth);

        setTimeout(() => {
            try {
                document.querySelector("#booking-calendar").classList.remove("loading");
            } catch (error) {}
        }, 50);
    };

    const prevMonth = () => {
        newload(-1);
    };
    const nextMonth = () => {
        newload(1);
    };

    const goToPrenota = () => {
        navigate("/conferma-prenotazione", {
            // state: {
            //     slotId: selectedSlot.id,
            //     slotFrom: `${selectedSlot.from}:00`,
            //     slotTo: `${selectedSlot.to}:00`,
            //     date: selectedDate.dateObj.toISOString(),
            // },
            // @todo rifare, vecchia gestione con orari pieni
            state: {
                slotId: selectedSlot.id,
                slotFrom: `00:00`,
                slotTo: `23:59`,
                date: selectedDate.dateObj.toISOString(),
                isLastDate: lastDate.isSame(selectedDate.dateObj, "date")
            },
        });
    };

    return {
        read,
        update,
        slots,
        selectedSlot,
        setSelectedSlot,
        selectedDate,
        setSelectedDate,
        currentMonthIdx,
        currentMonth,
        daysInCurrentMonth,
        prevMonth,
        nextMonth,
        goToPrenota,
        handleCalendarTouchStart,
        handleCalendarTouchMove,
        handleCalendarTouchEnd,
    };
};
